import React from "react";
import WritingTemplate from "../../components/writing/template";
import Card from "../../components/writing/template/card";

import story from "../../images/writing/inmemoryheader.png";

export default function () {
  return (
    <WritingTemplate
      title="In Memory of Her"
      subtitle="In loving memory of her infested mind"
      imageLink={story}
    >
      <Card>
        <p>
          on my tombstone, I lay these bouquets; withered orchids and starved
          roses. visible and substantial enough to express a great divide. an
          exit with an apparent fight, unchallenging.
        </p>
        <p>
          I liked to assume that the process of dying is intimately ushered by
          struggle, agony and anguish. Mine wasn’t. It was blissful like the
          dawn after an apocalypse. The relief that comes unhooking your bra. I
          swear on my WAP it was…seventh heaven.
        </p>
        <p>
          It was one hell of a battle. No, not the part where I fought back, it
          was the part where I surrendered to defeat because I had exhausted my
          last bar on the battle to stay alive. It was time to go.
        </p>
        <p className="different">
          On that bright and cloudless morning, I laid to rest the murky and
          hazed part of me. the part that was okay with if my failure unfolded
          out of my decision to not try. To give up without a fight. the part of
          me that was consistently giving excuses to come last in everybody
          else’s world. Why I can be half-loved, Why it’s okay to not count, to
          be unworthy, despicable, pitiful. I let her die, facilely.
        </p>
        <p>
          In my ceremonial dress and slingback, I stood at the entrance of my
          own tomb where my infested mind was laid, I confidently threw my
          middle finger and waved it like a freaking trophy.
        </p>
        <p>
          Here’s to me being in control of my mind, body, sanity and heart and
          here’s to me running it however the fuck I want.
        </p>
      </Card>
    </WritingTemplate>
  );
}
